import { Content, ContentHeader, CreateButton, PageWithHeader, SupportButton } from "@backstage/core-components";
import { configApiRef, useApi, useRouteRef } from "@backstage/core-plugin-api";
import { CatalogKindHeader, catalogPlugin } from "@backstage/plugin-catalog";
import {
  CatalogFilterLayout, EntityLifecyclePicker, EntityListProvider,
  EntityOwnerPicker, EntityProcessingStatusPicker,
  EntityTagPicker, EntityTypePicker, UserListPicker
} from "@backstage/plugin-catalog-react";
import React from "react";
import { ServiceCatalogTable } from "./ServiceCatalogTable";

export const ServiceCatalogPage = ({
  actions,
  initiallySelectedFilter = 'owned',
}) => {
  const orgName =
    useApi(configApiRef).getOptionalString('organization.name') ?? 'Backstage';
  const createComponentLink = useRouteRef(
    catalogPlugin.externalRoutes.createComponent,
  );

  return (
    React.createElement(PageWithHeader, { title: `${orgName} Catalog`, themeId: "home",}
      , React.createElement(EntityListProvider, null
        , React.createElement(Content, null
          , React.createElement(ContentHeader, { titleComponent: React.createElement(CatalogKindHeader, null ),}
            , React.createElement(CreateButton, { title: "Create Component" , to: createComponentLink && createComponentLink(),} )
            , React.createElement(SupportButton, null, "All your software catalog entities"    )
          )
          , React.createElement(CatalogFilterLayout, null
            , React.createElement(CatalogFilterLayout.Filters, null
              , React.createElement(EntityTypePicker, null )
              , React.createElement(UserListPicker, { initialFilter: initiallySelectedFilter,} )
              , React.createElement(EntityOwnerPicker, null )
              , React.createElement(EntityLifecyclePicker, null )
              , React.createElement(EntityTagPicker, null )
              , React.createElement(EntityProcessingStatusPicker, null )
            )
            , React.createElement(CatalogFilterLayout.Content, null
              , React.createElement(ServiceCatalogTable, { actions: actions,} )
            )
          )
        )
      )
    )
  );
};