import { createTheme, lightTheme } from '@backstage/theme';
import { color } from '@internal/plugin-components-react';
import createCustomThemeOverrides from './customThemeOverrides';

const baseTheme = createTheme({
  palette: {
    ...lightTheme.palette,
    action: {
      hover: color.ui.states.hover,
      focus: color.ui.states.pressed,
      disabled: color.ui.gray[300],
    },
    borderColor: {
      primary: color.ui.gray[200],
      secondary: color.ui.gray[100],
      tertiary: color.ui.gray[600],
    },
    primary: {
      main: color.ui.blue[200],
      light: color.ui.ab[800],
      dark: color.ui.ab[200],
      contrastText: color.ui.blue[900],
      backgroundSoft: color.ui.ab[900],
    },
    secondary: {
      main: color.ui.gray[600],
      light: color.ui.gray[800],
      dark: color.ui.gray[300],
      contrastText: color.ui.common.white,
      backgroundSoft: color.ui.gray[800],
    },
    error: {
      main: color.ui.red[300],
      light: color.ui.red[600],
      dark: color.ui.red[200],
      contrastText: color.ui.red[900],
      backgroundSoft: color.ui.red[900],
    },
    warning: {
      main: color.ui.orange[500],
      light: color.ui.orange[700],
      dark: color.ui.orange[100],
      contrastText: color.ui.orange[900],
      backgroundSoft: color.ui.orange[900],
      darkStrong: color.ui.orange[100],
    },
    info: {
      main: color.ui.blue[500],
      light: color.ui.blue[700],
      dark: color.ui.blue[300],
      contrastText: color.ui.blue[900],
      backgroundSoft: color.ui.blue[900],
    },
    success: {
      main: color.ui.green[500],
      light: color.ui.green[300],
      dark: color.ui.green[200],
      contrastText: color.ui.green[900],
      backgroundSoft: color.ui.green[900],
    },
    important: {
      main: color.ui.purple[500],
      light: color.ui.purple[800],
      dark: color.ui.purple[200],
      contrastText: color.ui.purple[800],
      backgroundSoft: color.ui.purple[900],
    },
    neutral: {
      main: color.ui.gray[400],
      light: color.ui.gray[800],
      dark: color.ui.gray[400],
      backgroundSoft: color.ui.additional[50],
      contrastText: color.ui.gray[50],
      disabledBackground: color.ui.gray[800],
    },
    text: {
      primary: color.ui.gray[100],
      secondary: color.ui.gray[400],
      hint: color.ui.gray[600],
      disabled: color.ui.gray[600],
    },
    icon: {
      active: color.ui.gray[300],
      disabled: color.ui.gray[800],
      contrast: color.ui.common.white,
      strong: color.ui.gray[500],
    },
    background: {
      default: color.ui.additional[200],
      paper: color.ui.additional[100],
      surfaceVariant: color.ui.gray[800],
      superSoft: color.ui.additional[200],
      disabled: color.ui.gray[800],
    },
    border: color.ui.gray[800],
    textContrast: color.ui.gray[100],
    textSubtle: color.ui.gray[300],
    textVerySubtle: color.ui.gray[600],
    navigation: {
      background: color.ui.additional[100],
      submenu: {
        background: color.ui.gray[700],
      },
      navItem: {
        hoverBackground: color.ui.gray[500],
      },
      color: color.ui.gray[400],
      selectedColor: color.ui.common.white,
      indicator: color.ui.blue[200],
    },
    status: {
      ...lightTheme.palette.status,
      error: color.ui.red[300],
      ok: color.ui.green[500],
      warning: color.ui.orange[500],
    },
    textColor: {
      textContrast: color.ui.gray[100],
      textSubtle: color.ui.gray[400],
      textVerySubtle: color.ui.gray[600],
      softDisabledText: color.ui.gray[600],
      inverseText: color.ui.gray[900],
    },
  },
  fontFamily: 'Inter',
  defaultPageTheme: 'home',
});

export const abDarkTheme = {
  ...baseTheme,
  overrides: {
    ...baseTheme.overrides,
    ...createCustomThemeOverrides(baseTheme),
  },
};
