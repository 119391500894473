import { makeStyles } from '@material-ui/core';


export const useAboutPageStyle = makeStyles(theme => ({
  primary: {
    textTransform: 'uppercase',
    color: theme.palette.text.secondary,
    fontWeight: 700,
  },
  secondary: {
    color: theme.palette.text.primary,
    fontWeight: 700,
  },
}));
