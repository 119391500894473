import { makeStyles } from '@material-ui/core';
import { sidebarConfig } from '@backstage/core-components';


export const useSidebarLogoStyles = makeStyles({
  root: {
    width: sidebarConfig.drawerWidthClosed,
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: -14,
  },
  link: {
    width: sidebarConfig.drawerWidthClosed,
    marginLeft: 24,
  },
});

export const launchIconBoxStyle = {
  width: '100%',
  display: 'flex',
};
