import React from 'react';

const IFRAME_SRC =
  'https://docs.google.com/spreadsheets/d/e/2PACX-1vRF-NOltwdemPT_gXwMvVsyy6NHVbRFidb1DyLDmNllJ3Xer2s6VKuw637H7HwxONsss4Fqx7xwbq4a/pubhtml?widget=true&amp;headers=false';

export default function MaintenanceCalendarPage() {
  return (
    React.createElement('iframe', {
      src: IFRAME_SRC,
      style: { width: '100%', height: '100vh', border: 'none' },}
    )
  );
}
