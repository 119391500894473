
import { CatalogTable, } from "@backstage/plugin-catalog";
import { useEntityList } from "@backstage/plugin-catalog-react";
import React from "react";
import { Logos } from '@internal/plugin-components-react';


const createProjectColumn = () => {
  const getProjectLogo = (name) => {
    if (/^avengers/.test(name)) {
      return React.createElement(Logos.BlackBoxLogoFull, null )
    }

    return null
  }

  return {
    title: 'Project',
    field: 'resolved.name',
    render: ({ resolved }) => getProjectLogo(resolved.name)
  }
}

const createEntitySpecificColumns = (kind) => {
  switch (kind) {
    case 'user':
      return [];
    case 'domain':
    case 'system':
      return [CatalogTable.columns.createOwnerColumn()];
    case 'group':
    case 'template':
      return [CatalogTable.columns.createSpecTypeColumn()];
    case 'location':
      return [
        CatalogTable.columns.createSpecTypeColumn(),
        CatalogTable.columns.createSpecTargetsColumn(),
      ];
    default:
      return [
        createProjectColumn(),
        CatalogTable.columns.createOwnerColumn(),
        CatalogTable.columns.createSpecTypeColumn(),
        CatalogTable.columns.createSpecTypeColumn(),
        CatalogTable.columns.createSpecLifecycleColumn(),
      ];
  }
}

export const ServiceCatalogTable = (props) => {
  const { filters } = useEntityList();

  const columns = [
    CatalogTable.columns.createTitleColumn({ hidden: true }),
    CatalogTable.columns.createNameColumn({ defaultKind: filters.kind?.value }),
    ...createEntitySpecificColumns(filters.kind?.value),
    CatalogTable.columns.createMetadataDescriptionColumn(),
    CatalogTable.columns.createTagsColumn(),
  ]

  return React.createElement(CatalogTable, { ...props, columns: columns,} );
}