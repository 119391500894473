/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Box, Link } from '@material-ui/core';
import CreateComponentIcon from '@material-ui/icons/AddCircleOutline';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import CloudIcon from '@material-ui/icons/Cloud';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';
import GamepadIcon from '@material-ui/icons/Gamepad';
import HomeIcon from '@material-ui/icons/Home';
import LaunchIcon from '@material-ui/icons/Launch';
import MapIcon from '@material-ui/icons/MyLocation';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import SettingsInputComponentIcon from '@material-ui/icons/SettingsInputComponent';
import React, { } from 'react';

import { FeatureFlagged } from '@backstage/core-app-api';
import {
  Sidebar,
  SidebarDivider,
  SidebarGroup,
  SidebarItem,
  SidebarPage,
  SidebarScrollWrapper,
  SidebarSpace,
  SidebarSubmenu,
  SidebarSubmenuItem,
  useSidebarOpenState,
} from '@backstage/core-components';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import {
  Settings as SidebarSettings,
  UserSettingsSignInAvatar,
} from '@backstage/plugin-user-settings';
import { PermissionedRoute } from '@internal/plugin-access-control-react';
import { Icons, Logos } from '@internal/plugin-components-react';
import FilterDrama from '@material-ui/icons/FilterDrama';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import TableChart from '@material-ui/icons/TableChart';
import { NavLink } from 'react-router-dom';
import { FlaggedFeature } from '../../constants';
import { launchIconBoxStyle, useSidebarLogoStyles } from './style';

const { AccelByteLogoFull, AccelByteLogoIcon } = Logos;
const { AccessControlIcon } = Icons;

const SidebarLogo = () => {
  const classes = useSidebarLogoStyles();
  const { isOpen } = useSidebarOpenState();

  return (
    React.createElement('div', { className: classes.root,}
      , React.createElement(Link, {
        component: NavLink,
        to: "/",
        underline: "none",
        className: classes.link,
        'aria-label': "Home",}
      
        , isOpen ? React.createElement(AccelByteLogoFull, null ) : React.createElement(AccelByteLogoIcon, null )
      )
    )
  );
};

export const Root = ({ children }) => {
  const config = useApi(configApiRef);
  const aspDashboardLink = config.getString('integrations.aspDashboardUrl');

  return (
    React.createElement(SidebarPage, null
      , React.createElement(Sidebar, null
        , React.createElement(SidebarLogo, null )
        , React.createElement(SidebarGroup, { label: "Menu", icon: React.createElement(MenuIcon, null ),}
          , React.createElement(SidebarItem, {
            icon: Icons.AppManagementIcon,
            to: "/cluster-management/applications",
            text: "App Management" ,}
          )
          , React.createElement(SidebarItem, {
            icon: Icons.EnvManagementIcon,
            to: "/cluster-management/environments",
            text: "Environments",}
          )
          , React.createElement(FeatureFlagged, { with: FlaggedFeature['backstage-catalog'],}
            /* Global nav, not org-specific */
            , React.createElement(SidebarItem, { icon: HomeIcon, to: "catalog", text: "Catalog",} )
          )
          , React.createElement(FeatureFlagged, { with: FlaggedFeature['backstage-cluster-management'],}
            , React.createElement(PermissionedRoute, {
              module: "cluster-page",
              errorComponent: React.createElement(React.Fragment, null),
              element: 
                React.createElement(SidebarItem, { icon: CloudIcon, text: "Cluster",}
                  , React.createElement(SidebarSubmenu, { title: "Cluster",}
                    , React.createElement(SidebarSubmenuItem, {
                      title: "Separated View" ,
                      to: "/clusters",
                      icon: FilterDrama,}
                    )
                    , React.createElement(SidebarSubmenuItem, {
                      title: "Table View" ,
                      to: "/clustertable",
                      icon: TableChart,}
                    )
                  )
                )
              ,}
            )
          )
          , React.createElement(SidebarItem, { icon: GamepadIcon, text: "Game Lifecycle" ,}
            , React.createElement(SidebarSubmenu, { title: "Game Lifecycle" ,}
              , React.createElement(SidebarSubmenuItem, {
                icon: AttachMoneyIcon,
                to: "/cost-dashboard",
                title: "Cost Dashboard" ,}
              )
              , React.createElement(SidebarSubmenuItem, {
                icon: AddToPhotosIcon,
                to: "https://accelbyte.atlassian.net/servicedesk/customer/portal/19/group/61/create/249",
                title: "Provision New Env"  ,}
              )
              , React.createElement(SidebarSubmenuItem, {
                icon: FlightTakeoffIcon,
                to: "/game-launches",
                title: "Game Launch" ,}
              )
              , React.createElement(SidebarSubmenuItem, {
                icon: SettingsInputComponentIcon,
                to: "/psn-whitelist",
                title: "PSN Whitelist" ,}
              )
              , React.createElement(SidebarSubmenuItem, {
                icon: NotificationImportantIcon,
                to: "/alert-and-subscription",
                title: "Alerts & Subscription"  ,}
              )
              , React.createElement(FeatureFlagged, { with: FlaggedFeature['announcement'],}
                , React.createElement(SidebarSubmenuItem, {
                  icon: RecordVoiceOverIcon,
                  to: "/announcement",
                  title: "Announcement",}
                )
              )
            )
          )
          , React.createElement(SidebarItem, {
            icon: Icons.DeploymentManagementIcon,
            text: "Deployment Management" ,
            to: "/deployment-management",}
          
            , React.createElement(SidebarSubmenu, { title: "Deployment Management" ,}
              , React.createElement(SidebarSubmenuItem, {
                to: "/deployment-management/deployment-plan",
                title: "Deployment Plan" ,}
              )
              , React.createElement(SidebarSubmenuItem, {
                to: "/deployment-management/release-history",
                title: "Release History" ,}
              )
              , React.createElement(SidebarSubmenuItem, {
                to: "/deployment-management/env-var",
                title: "Customer Env Var"  ,}
              )
            )
          )

          , React.createElement(PermissionedRoute, {
            module: "access-control-page",
            errorComponent: React.createElement(React.Fragment, null),
            element: 
              React.createElement(SidebarItem, {
                icon: AccessControlIcon,
                to: "/access-control",
                text: "Access Control" ,}
              )
            ,}
          )
          , React.createElement(SidebarItem, {
            icon: Icons.AspDashboardIcon,
            text: "ASP Dashboard" ,
            target: "_blank",
            onClick: () => window.open(aspDashboardLink, '_blank'),}
          
            , React.createElement(Box, { style: launchIconBoxStyle,}
              , React.createElement(LaunchIcon, null )
            )
          )
          , React.createElement(SidebarItem, {
            icon: CalendarTodayIcon,
            to: "/maintenance-calendar",
            text: "Maintenance Calendar" ,}
          )
          /*
        <SidebarItem icon={ExtensionIcon} to="api-docs" text="APIs" />
        <SidebarItem icon={LibraryBooks} to="docs" text="Docs" />
        */
          , React.createElement(FeatureFlagged, { with: FlaggedFeature['backstage-catalog'],}
            , React.createElement(SidebarItem, {
              icon: CreateComponentIcon,
              to: "create",
              text: "Create...",}
            )
          )
          /* End global nav */
          , React.createElement(SidebarDivider, null )
          , React.createElement(SidebarScrollWrapper, null
            , React.createElement(SidebarItem, { icon: MapIcon, to: "tech-radar", text: "Tech Radar" ,} )
          )
          , React.createElement(SidebarDivider, null )
          , React.createElement(PermissionedRoute, {
            module: "blackbox-page",
            errorComponent: React.createElement(React.Fragment, null),
            element: 
              React.createElement(SidebarItem, { icon: Icons.AppManagementIcon, text: "Blackbox Menu" ,}
                , React.createElement(SidebarSubmenu, { title: "Blackbox Menu" ,}
                  , React.createElement(SidebarSubmenuItem, {
                    icon: FlightTakeoffIcon,
                    to: "/preflight",
                    title: "Preflight",}
                  )
                  , React.createElement(SidebarSubmenuItem, {
                    icon: CreateComponentIcon,
                    to: "/release-tools",
                    title: "Release Tools" ,}
                  )
                )
              )
            ,}
          )
        )
        , React.createElement(SidebarSpace, null )
        , React.createElement(SidebarDivider, null )
        , React.createElement(SidebarGroup, {
          label: "Settings",
          icon: React.createElement(UserSettingsSignInAvatar, null ),
          to: "/settings",}
        
          , React.createElement(SidebarSettings, null )
        )
      )
      , children
    )
  );
};
