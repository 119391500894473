import React from 'react';
import { Navigate, Route } from 'react-router';
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import { CatalogEntityPage, catalogPlugin } from '@backstage/plugin-catalog';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { orgPlugin } from '@backstage/plugin-org';
import { SearchPage } from '@backstage/plugin-search';
import { TechRadarPage } from '@backstage/plugin-tech-radar';
import {
  TechDocsIndexPage,
  techdocsPlugin,
  TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
import {
  UserSettingsPage,
  UserSettingsTab,
} from '@backstage/plugin-user-settings';
import { apis } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { searchPage } from './components/search/SearchPage';
import { Root } from './components/Root';

import { AlertDisplay, OAuthRequestDialog } from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { FlatRoutes, FeatureFlagged } from '@backstage/core-app-api';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { PermissionedRoute } from '@internal/plugin-access-control-react';

import { googleAuthApiRef } from '@backstage/core-plugin-api';
import { SignInPage } from '@backstage/core-components';

import {
  EntityClusterContentAllTable,
  EntityClusterList,
} from '@internal/plugin-cluster-agent';
import { CostDashboardPage } from '@internal/backstage-plugin-cost-dashboard';
import { EntityGluesManagementPage } from '@internal/backstage-plugin-glues-management';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { abLightTheme } from './theme/abLightTheme';
import { abDarkTheme } from './theme/abDarkTheme';
import { PSNReminderPage } from '@internal/plugin-psn-reminder';
import { PermissionErrorPage } from './components/error/PermissionErrorPage';
import { ServiceCatalogPage } from './components/catalog/ServiceCatalogPage';
import { AppManagementPage } from '@internal/plugin-cluster-management/src/components/AppManagementPage';
import { AppManagementClusterPage } from '@internal/plugin-cluster-management/src/components/AppManagementClusterPage';
import {
  AccessControlPage,
  GroupAccessPage,
} from '@internal/plugin-access-control';
import {
  ArmadaCalcPage,
  GameLaunchesPage,
  GameLaunchesDetailsPage,
} from '@internal/plugin-game-launches';
import { UserAccessPage } from '@internal/plugin-access-control/src/components/UserAccessPage';
import {
  EnvManagementEnvironmentsPage,
  EnvManagementApplicationsPage,
} from '@internal/plugin-cluster-management';
import {
  AlertAndSubscriptionPage,
  AlertDetails,
} from '@internal/plugin-alert-and-subscription';
import { FlaggedFeature } from './constants';
import MaintenanceCalendarPage from './components/maintenanceCalendar/MaintenanceCalendarPage';
import {
  DeploymentPlanPage,
  DeploymentPlanDetailPage,
  ReleaseHistoryPage,
  DeploymentPlanCreationPage,
} from '@internal/plugin-deployment-management';
import {
  AppGluesManagementPage,
  EnvGluesManagementPage,
} from '@internal/plugin-cluster-management/src/components/AppGluesManagementPage';
import { ReleaseTagPage } from '@internal/plugin-deployment-management/src/components/ReleaseTagPage';
import { CustomerEnvVarPage } from '@internal/plugin-deployment-management/src/components/CustomerEnvVar/CustomerEnvVarPage';
import { AboutPage } from './components/about/About';
import { AnnouncementPage } from '@internal/plugin-announcement';

const app = createApp({
  apis,
  plugins: [],
  components: {
    SignInPage: props => (
      React.createElement(SignInPage, {
        ...props,
        auto: true,
        provider: {
          id: 'google-auth-provider',
          title: 'Google',
          message: 'Sign in using Google',
          apiRef: googleAuthApiRef,
        },}
      )
    ),
  },
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
  themes: [
    {
      id: 'accelbytelight',
      title: 'AccelByte Light',
      variant: 'light',
      Provider: ({ children }) => (
        React.createElement(ThemeProvider, { theme: abLightTheme,}
          , React.createElement(CssBaseline, null, children)
        )
      ),
    },
    {
      id: 'accelbytedark',
      title: 'AccelByte Dark',
      variant: 'dark',
      Provider: ({ children }) => (
        React.createElement(ThemeProvider, { theme: abDarkTheme,}
          , React.createElement(CssBaseline, null, children)
        )
      ),
    },
  ],
});

const AppProvider = app.getProvider();
const AppRouter = app.getRouter();

const backstageFeatureFlagRegistrant = (
  React.createElement(React.Fragment, null
    , React.createElement(FeatureFlagged, { with: FlaggedFeature['backstage-catalog'],}
      , React.createElement(React.Fragment, null)
    )
    , React.createElement(FeatureFlagged, { with: FlaggedFeature['backstage-cluster-management'],}
      , React.createElement(React.Fragment, null)
    )
  )
);

const routes = (
  React.createElement(FlatRoutes, null
    , React.createElement(Navigate, { key: "/", to: "/cluster-management/applications",} )
    , React.createElement(Route, { path: "/catalog", element: React.createElement(ServiceCatalogPage, null ),} )
    , React.createElement(Route, {
      path: "/catalog/:namespace/:kind/:name",
      element: React.createElement(CatalogEntityPage, null ),}
    
      , entityPage
    )
    , React.createElement(Route, { path: "/docs", element: React.createElement(TechDocsIndexPage, null ),} )
    , React.createElement(Route, {
      path: "/docs/:namespace/:kind/:name/*",
      element: React.createElement(TechDocsReaderPage, null ),}
    
      , React.createElement(TechDocsAddons, null
        , React.createElement(ReportIssue, null )
      )
    )
    , React.createElement(Route, { path: "/create", element: React.createElement(ScaffolderPage, null ),} )
    , React.createElement(Route, { path: "/api-docs", element: React.createElement(ApiExplorerPage, null ),} )
    , React.createElement(Route, {
      path: "/tech-radar",
      element: React.createElement(TechRadarPage, { width: 1500, height: 800,} ),}
    )
    , React.createElement(PermissionedRoute, {
      path: "/catalog-import",
      module: "catalog-import-page",
      errorComponent: React.createElement(PermissionErrorPage, null ),
      element: React.createElement(CatalogImportPage, null ),}
    )
    , React.createElement(Route, { path: "/search", element: React.createElement(SearchPage, null ),}
      , searchPage
    )
    , React.createElement(Route, { path: "/settings", element: React.createElement(UserSettingsPage, null ),}
      , React.createElement(UserSettingsTab, { path: "/about", title: "About",}
        , React.createElement(AboutPage, null )
      )
    )
    , React.createElement(Route, { path: "/catalog-graph", element: React.createElement(CatalogGraphPage, null ),} )

    , React.createElement(PermissionedRoute, {
      path: "/clusters",
      module: "cluster-page",
      errorComponent: React.createElement(PermissionErrorPage, null ),
      element: React.createElement(EntityClusterList, null ),}
    )
    , React.createElement(PermissionedRoute, {
      path: "/clustertable",
      module: "cluster-page",
      errorComponent: React.createElement(PermissionErrorPage, null ),
      element: React.createElement(EntityClusterContentAllTable, null ),}
    )

    , React.createElement(Route, { path: "/cost-dashboard", element: React.createElement(CostDashboardPage, null ),} )
    , React.createElement(Route, {
      path: "/catalog/:namespace/:kind/:name/metric/:cluster/glues-management/*",
      element: React.createElement(EntityGluesManagementPage, null ),}
    )
    , React.createElement(PermissionedRoute, {
      path: "/access-control",
      module: "access-control-page",
      errorComponent: React.createElement(PermissionErrorPage, null ),
      element: React.createElement(AccessControlPage, null ),}
    )
    , React.createElement(PermissionedRoute, {
      path: "/access-control/user/:userId",
      module: "access-control-page",
      errorComponent: React.createElement(PermissionErrorPage, null ),
      element: React.createElement(UserAccessPage, null ),}
    )
    , React.createElement(PermissionedRoute, {
      path: "/access-control/group/:groupId",
      module: "access-control-page",
      errorComponent: React.createElement(PermissionErrorPage, null ),
      element: React.createElement(GroupAccessPage, null ),}
    )

    , React.createElement(Route, {
      path: "/catalog/:namespace/:kind/:name/metric/:cluster/glues-management/*",
      element: React.createElement(EntityGluesManagementPage, null ),}
    )
    , React.createElement(Route, { path: "/psn-whitelist", element: React.createElement(PSNReminderPage, null ),} )
    , React.createElement(Route, { path: "/game-launches", element: React.createElement(GameLaunchesPage, null ),} )
    , React.createElement(Route, { path: "/game-launches/:id", element: React.createElement(GameLaunchesDetailsPage, null ),} )
    , React.createElement(Route, { path: "/game-launches/:id/armada-calc", element: React.createElement(ArmadaCalcPage, null ),} )
    , React.createElement(Route, {
      path: "/cluster-management/applications",
      element: React.createElement(AppManagementPage, null ),}
    )
    , React.createElement(Route, {
      path: "/cluster-management/applications/:applicationId",
      element: React.createElement(AppManagementClusterPage, null ),}
    )
    , React.createElement(Route, {
      path: "/cluster-management/applications/:applicationId/:environmentId",
      element: React.createElement(AppGluesManagementPage, null ),}
    )
    , React.createElement(Route, {
      path: "/cluster-management/environments",
      element: React.createElement(EnvManagementEnvironmentsPage, null ),}
    )
    , React.createElement(Route, {
      path: "/cluster-management/environments/:environmentId",
      element: React.createElement(EnvManagementApplicationsPage, null ),}
    )
    , React.createElement(Route, {
      path: "/cluster-management/environments/:environmentId/:applicationId",
      element: React.createElement(EnvGluesManagementPage, null ),}
    )
    , React.createElement(Route, {
      path: "/deployment-management",
      element: React.createElement(Navigate, { replace: true, to: "/deployment-management/deployment-plan",} ),}
    )
    , React.createElement(Route, {
      path: "/deployment-management/deployment-plan",
      element: React.createElement(DeploymentPlanPage, null ),}
    )
    , React.createElement(Route, {
      path: "/deployment-management/deployment-plan/:environmentId",
      element: React.createElement(DeploymentPlanPage, null ),}
    )
    , React.createElement(Route, {
      path: "/deployment-management/deployment-plan/:environmentId/:deploymentPlanId",
      element: React.createElement(DeploymentPlanDetailPage, null ),}
    )
    , React.createElement(Route, {
      path: "/deployment-management/deployment-plan/create",
      element: React.createElement(DeploymentPlanCreationPage, null ),}
    )
    , React.createElement(Route, {
      path: "/deployment-management/release-history",
      element: React.createElement(ReleaseHistoryPage, null ),}
    )
    , React.createElement(Route, {
      path: "/deployment-management/release-history/:releaseId",
      element: React.createElement(ReleaseHistoryPage, null ),}
    )
    , React.createElement(Route, {
      path: "/deployment-management/release-history/:releaseId/tag",
      element: React.createElement(ReleaseTagPage, null ),}
    )
    , React.createElement(Route, {
      path: "/deployment-management/env-var",
      element: React.createElement(CustomerEnvVarPage, null ),}
    )
    , React.createElement(Route, {
      path: "/deployment-management/env-var/:environmentId",
      element: React.createElement(CustomerEnvVarPage, null ),}
    )
    , React.createElement(Route, {
      path: "/alert-and-subscription",
      element: React.createElement(AlertAndSubscriptionPage, null ),}
    )
    , React.createElement(Route, {
      path: "/alert-and-subscription/alert/:id",
      element: React.createElement(AlertDetails, null ),}
    )
    , React.createElement(Route, { path: "/maintenance-calendar", element: React.createElement(MaintenanceCalendarPage, null ),} )
    , backstageFeatureFlagRegistrant
    , React.createElement(Route, { path: "/announcement", element: React.createElement(AnnouncementPage, null ),} )
  )
);

const App = () => (
  React.createElement(AppProvider, null
    , React.createElement(AlertDisplay, null )
    , React.createElement(OAuthRequestDialog, null )
    , React.createElement(AppRouter, null
      , React.createElement(Root, null, routes)
    )
  )
);

export default App;
