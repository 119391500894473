import React from 'react';
import { InfoCard, Link } from '@backstage/core-components';
import {
  List,
  Grid,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { useAboutPageStyle } from './style';

export const AboutPage = () => {
  const classes = useAboutPageStyle();
  const conf = useApi(configApiRef);
  const gitHash = conf.getOptionalString('app.about.gitHash') ?? 'Unknown';
  const revisionId =
    conf.getOptionalString('app.about.revisionId') ?? 'Unknown';
  const bbRepoUrl = conf.getOptionalString('app.about.bbRepoUrl') ?? 'Unknown';

  return (
    React.createElement(Grid, { container: true, direction: "row", spacing: 3,}
      , React.createElement(Grid, { item: true, xs: 12, md: 6,}
        , React.createElement(InfoCard, { title: "About DevPortal" , variant: "gridItem",}
          , React.createElement(List, null
            , React.createElement(ListItem, null
              , React.createElement(ListItemText, {
                primary: 
                  React.createElement(Typography, { variant: "subtitle2", className: classes.primary,}, "HASH"

                  )
                ,
                secondary: 
                  React.createElement(Typography, { variant: "body1", className: classes.secondary,}
                    , React.createElement(Link, {
                      to: `${bbRepoUrl}/commits/${gitHash}`,
                      target: "_blank",}
                    
                      , gitHash
                    )
                  )
                ,}
              )
            )
            , React.createElement(ListItem, null
              , React.createElement(ListItemText, {
                primary: 
                  React.createElement(Typography, { variant: "subtitle2", className: classes.primary,}, "VERSION"

                  )
                ,
                secondary: 
                  React.createElement(Typography, { variant: "body1", className: classes.secondary,}
                    , revisionId
                  )
                ,}
              )
            )
          )
        )
      )
    )
  );
};
