


import { color } from '@internal/plugin-components-react';

const createCustomThemeOverrides = (
  theme,
) => {
  return {
    BackstageTableToolbar: {
      searchField: {
        backgroundColor: theme.palette.background.paper,
        '&>div>div>svg': {
          color: theme.palette.icon.active,
        },
        '&>div': {
          borderBottom: `1px solid ${theme.palette.borderColor.primary}`,
        },
        '&>div:hover': {
          borderBottom: `1px solid ${theme.palette.borderColor.primary}`,
        },
      },
    },
    BackstageHeader: {
      header: {
        paddingLeft: 42,
        paddingRight: 42,
        backgroundImage: 'none',
        backgroundColor: theme.palette.background.paper,
        boxShadow: 'none',
        '& [class*="MuiIconButton-label-"]': {
          color: color.ui.common.black,
        },
      },
      title: {
        color: theme.palette.textContrast,
        fontWeight: 700,
        fontSize: 32,
      },
      subtitle: {
        color: theme.palette.text.secondary,
        fontSize: 14,
      },
      type: {
        color: color.ui.gray[800],
        fontSize: 11,
      },
    },
    BackstageHeaderLabel: {
      label: {
        color: theme.palette.text.primary,
      },
      value: {
        color: theme.palette.text.secondary,
      },
    },
    BackstageHeaderTabs: {
      defaultTab: {
        fontWeight: 700,
        paddingLeft: 12,
        paddingRight: 12,
      },
    },
    BackstageSidebarItem: {
      selected: {
        borderLeftColor: `${theme.palette.primary.main} !important`,
      },
    },
    BackstageSelectInputBase: {
      input: {
        border: `1px solid ${theme.palette.border}`,
      },
    },
    BackstageClosedDropdown: {
      icon: {
        color: theme.palette.icon.contrast,
      },
    },
    BackstageOpenedDropdown: {
      icon: {
        color: theme.palette.icon.contrast,
      },
    },
    BackstageWarningPanel: {
      summaryText: {
        color: theme.palette.text.primary,
      },
    },
    BackstageTable: {
      root: {
        '&> :first-child': {
          boxShadow: 'none',
        },
        width: '100%',
      },
    },
    BackstageTableHeader: {
      header: {
        fontSize: 12,
        fontWeight: 700,
        borderTopStyle: 'solid',
        borderBottomStyle: 'solid',
        borderColor: color.ui.gray[300],
        borderWidth: 1,
      },
    },
    BackstageGaugeCard: {
      root: {
        height: 'fit-content',
      },
    },
    BackstageContent: {
      root: {
        backgroundColor: theme.palette.background.default,
      },
    },
    MuiCircularProgress: {
      colorPrimary: {
        color: theme.palette.primary.dark,
      },
    },
    MuiSelect: {
      icon: {
        color: theme.palette.icon.active,
      },
    },
    MuiTabs: {
      indicator: {
        backgroundColor: theme.palette.primary.main,
        height: 4,
      },
    },
    MuiTypography: {
      colorPrimary: {
        color: theme.palette.text.primary,
      },
      colorSecondary: {
        color: theme.palette.text.secondary,
      },
    },
    MuiLink: {
      root: {
        color: theme.palette.info.main,
      },
    },
    MuiTableHead: {
      root: {
        '&>tr>th': {
          color: theme.palette.text.primary,
        },
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: 'none',
        '& button': {
          color: theme.palette.primary.main,
        },
        '& [aria-label="Detail panel visiblity toggle"] > [class*="MuiIconButton-label"]':
          {
            color: theme.palette.secondary.dark,
          },
        '& [aria-label="Detail panel visiblity toggle"][style*=" transform: rotate(90deg)"] > [class*="MuiIconButton-label"]':
          {
            transform: 'rotate(90deg)',
          },
      },
      body: {
        color: color.ui.common.black,
      },
    },
    MuiTableRow: {
      root: {
        '&:nth-of-type(odd)': {
          background: theme.palette.background.superSoft,
        },
      },
    },
    MuiChip: {
      root: {
        '&svg:first-child': {
          marginLeft: 12,
        },
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.neutral.backgroundSoft,
      },
      deleteIcon: {
        color: theme.palette.icon.active,
      },
    },
    MuiIconButton: {
      root: {
        '&[class*="MuiAutocomplete-clearIndicator-"]': {
          color: theme.palette.icon.contrast,
        },
        '&[class*="MuiAutocomplete-popupIndicator-"]': {
          color: theme.palette.icon.contrast,
        },
      },
    },
    MuiDivider: {
      root: { backgroundColor: theme.palette.border },
    },
    MuiCheckbox: {
      colorPrimary: {
        '&.Mui-checked': {
          color: theme.palette.primary.main,
        },
        color: color.ui.gray[400],
      },
      colorSecondary: {
        '&.Mui-checked': {
          color: theme.palette.primary.main,
        },
        color: color.ui.gray[300],
      },
    },
    MuiSwitch: {
      colorSecondary: {
        '&>span>span': {
          color: theme.palette.icon.disabled,
        },
        '&+[class*="MuiSwitch-track-"]': {
          opacity: 1,
          backgroundColor: theme.palette.icon.disabled,
        },
        '&.Mui-checked>span>span': {
          color: theme.palette.primary.light,
        },
        '&.Mui-checked+[class*="MuiSwitch-track-"]': {
          opacity: 1,
          backgroundColor: theme.palette.primary.light,
        },
      },
      colorPrimary: {
        '&>span>span': {
          color: theme.palette.neutral.main,
        },
        '&+[class*="MuiSwitch-track-"]': {
          opacity: 1,
          backgroundColor: theme.palette.neutral.main,
        },
        '&.Mui-checked>span>span': {
          color: theme.palette.primary.main,
        },
        '&.Mui-checked+[class*="MuiSwitch-track-"]': {
          opacity: 1,
          backgroundColor: theme.palette.primary.main,
        },
      },
      track: {
        backgroundColor: theme.palette.textContrast,
      },
    },
    MuiRadio: {
      colorPrimary: {
        '&.Mui-checked': {
          color: theme.palette.primary.main,
        },
        color: theme.palette.primary.main,
      },
      colorSecondary: {
        '&.Mui-checked': {
          color: theme.palette.icon.disabled,
        },
        '&.Mui-checked [class*="PrivateRadioButtonIcon-layer-"]': {
          color: theme.palette.text.disabled,
        },
        color: theme.palette.icon.disabled,
      },
    },
    MuiTablePagination: {
      toolbar: {
        '& [class*="MuiTypography-caption-"]': {
          color: theme.palette.textSubtle,
        },
      },
      select: {
        paddingRight: 8,
        '&.MuiSelect-select.MuiSelect-select': {
          paddingRight: 12,
        },
      },
      selectRoot: {
        border: `1px solid ${color.ui.gray[300]}`,
        borderRadius: 4,
        backgroundColor: theme.palette.background.paper,
      },
    },
    MuiOutlinedInput: {
      root: {
        '&.Mui-error [class*="MuiOutlinedInput-notchedOutline"]': {
          borderColor: theme.palette.error.main,
        },
      },
    },
    MuiInput: {
      underline: {
        '&:before': {
          borderBottom: `1px solid ${theme.palette.border}`,
        },
      },
    },
    MuiInputBase: {
      root: {
        '&[class*="MuiOutlinedInput-root"]': {
          border: `1px solid ${theme.palette.border}`,
        },
        '&.Mui-disabled': {
          color: theme.palette.text.primary,
          background: theme.palette.neutral.disabledBackground,
        },
      },
    },
    MuiFormLabel: {
      root: {
        '&.Mui-error': {
          color: color.ui.ab[700],
        },
      },
      asterisk: {
        '&.Mui-error': {
          color: color.ui.ab[700],
        },
      },
    },
    MuiFormHelperText: {
      contained: {
        marginLeft: '0',
      },
    },
    MuiTextField: {
      root: {
        background: theme.palette.background.paper,
        color: theme.palette.text.primary,
      },
    },
    MuiButtonBase: {
      root: {
        '&[class*="MuiToggleButton-root-"]': {
          backgroundColor: theme.palette.background.paper,
          border: `1px solid ${theme.palette.border}`,
          color: theme.palette.text.primary,
        },
        '&[class*="MuiToggleButton-root-"].Mui-selected': {
          backgroundColor: theme.palette.primary.main,
          border: `1px solid ${theme.palette.border}`,
          color: theme.palette.primary.contrastText,
        },
      },
    },
    MuiButton: {
      contained: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
        '&:disabled': {
          color: theme.palette.text.disabled,
        },
      },
      outlined: {
        borderWidth: '0.1rem',
      },
      outlinedPrimary: {
        borderColor: theme.palette.primary.main,
      },
    },
    CatalogReactEntitySearchBar: {
      input: {
        color: 'black',
        '& > [class*="MuiInputAdornment-positionEnd-"] > [class*="MuiButtonBase-root-"]':
          {
            color: color.ui.gray[500],
          },
        '&:before': {
          borderBottomColor: color.ui.gray[300],
        },
        '& > input::placeholder': {
          color: color.ui.gray[600],
          opacity: 1,
        },
        '&:hover:not(.Mui-disabled):before ': {
          borderBottomColor: theme.palette.primary.main,
        },
      },
    },
    MuiBreadcrumbs: {
      li: {
        '& > a ': {
          color: theme.palette.textContrast,
        },
        '& > .MuiLink-underlineHover:hover ': {
          color: theme.palette.textContrast,
        },
      },
      ol: {
        color: theme.palette.textContrast,
      },
    },
    MuiTooltip: {
      tooltip: {
        color: color.ui.common.white,
        backgroundColor: color.ui.gray[600],
      },
    },
  };
};

export default createCustomThemeOverrides;
